import { put, takeLatest, select } from 'redux-saga/effects'
import get from 'lodash/get'
import installationPassportConfig from '@/constants/instalationPassport'
import request from '@/helpers/axios'
import {
  successGetCard,
  errorGetCard,
} from '@/store/actions/installation/getCard'
import {
  GEOZONE,
  PROJECT,
  ROOT,
  INTEGRATION,
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import { getSelectedElementParams } from '@/store/selectors/installation'
import { mapElementStructure } from '@/helpers/formatInstalationTree'
import {
  generateGetProjectCard,
  generateGetGeoZoneCard,
  generateGetTreeIntegrationElement,
  generateGetTypes,
  GET_PASSPORT_FILES_URL,
  generateGetGeoZoneCardStatistic,
  generateGetObjectCard,
} from '@/constants/apiRoutes'
import {
  GET_CARD,
  setSelectedElementParams,
} from '@/store/actions/installation'
import createNotifications from '@/helpers/notification'
import { successGetObjectTypes } from '@/store/actions/installation/getObjectTypes'
import { getGlobalFilters } from '@/store/selectors/appSettings'
import { setSelectedNode } from '@/store/actions/appSettings'
import { TYPE_TO_NAME } from '@/constants/maps'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'

export const isInConfig = (localName, config) => config
  .filter((configElement) => configElement.selector === localName).length !== 0
export const isAdditionalFields = (localName) => localName === 'DOCUMENT_CONTENT_TYPE'
|| localName === 'PHOTO_ID'
|| localName === 'PHOTO_CONTENT_TYPE'
|| localName === 'DOCUMENT_ID'

const requestToType = {
  [GEOZONE]: generateGetGeoZoneCard,
  [ROOT]: generateGetGeoZoneCard,
  [PROJECT]: generateGetProjectCard,
  [INTEGRATION]: generateGetTreeIntegrationElement,
  [OBJECT_ELEMENT]: generateGetObjectCard
}

function* getCardSaga({
  payload: {
    id, type, parentGeoZoneId, editMode, createMode, objectStates, isLiveTime, collumnId
  },
}) {
  try {
    let elementsInfo = {}
    const { asu , objectsTypes, integrations, processes, owners, exploitationOrganizations} = yield select(getGlobalFilters)
    const selectedElementParams = yield select(getSelectedElementParams)
    const { data } = yield request({
      url: (requestToType[type] || generateGetObjectCard)(id),
      method: 'get',
    })
    let parentElementName = {parentGeoZoneName: data.parentName, parentProjectName: data.parentName}
    const name = data.name
    const latitude = data.point.latitude.toFixed(6)
    const longitude = data.point.longitude.toFixed(6)
    if (!parentGeoZoneId && !editMode && !createMode && type !== OBJECT_ELEMENT) {
      const { data: elementStructureInfo } = yield request({
        url: generateGetGeoZoneCardStatistic({
          id,
          params: {
            objectStates,
            integrationTypes: asu,
            installationTypes: objectsTypes,
            integrationIds: integrations,
            cityProcessTypes: processes,
            owners,
            exploitationOrganizations
          }
        }),
        method: 'get',
      })
      const calcElementStructureInfo = mapElementStructure(elementStructureInfo)
      elementsInfo = {
        elementsInfo: calcElementStructureInfo,
      }
      yield put(setSelectedElementParams({
        id: id,
      }))
    }
    if (parentGeoZoneId && type !== OBJECT_ELEMENT && !editMode && !createMode) {
      const { data: elementStructureInfo } = yield request({
        url: generateGetGeoZoneCardStatistic({
          id,
          params: {
            objectStates,
            integrationTypes: asu,
            installationTypes: objectsTypes,
            integrationIds: integrations,
            cityProcessTypes: processes,
          }
        }),
        method: 'get',
      })
      const calcElementStructureInfo = mapElementStructure(elementStructureInfo)
      elementsInfo = {
        elementsInfo: calcElementStructureInfo,
      }
      let newZoom = 3
      if (type === GEOZONE && data.radius) {
        const pixelsInitialValue = 0.023
        const integerZoomValue = Array.from(Array(14), (_,x) => x + 1)
        const fractionalZoomValue = Array.from(Array(14), (_,item) => [item + 0.2, item + 0.4, item + 0.6, item + 0.8]).flat()
        const filteredFractionalZoomValue = fractionalZoomValue.filter(item => item >= 1)
        const zoomValues = [...integerZoomValue, ...filteredFractionalZoomValue].sort((a,b) => a - b)
        const mapZoomValuesToPx = zoomValues.map((item) => {
          return {
            zoom: item,
            pixels: pixelsInitialValue
          }
        }).reduce((acc, item, i) => {
          if (i) {
            if (item.zoom % 1 === 0 && i >= 5) {
              item.pixels = acc[i-5].pixels * 2
            } else {
              item.pixels = 0
            }
          }
          return [...acc, item];
        }, [])
        mapZoomValuesToPx.forEach((item, index) => {
          if (item.zoom % 1 !== 0 && index && index <= mapZoomValuesToPx.length) {
            item.pixels = mapZoomValuesToPx[index - 1].pixels + (mapZoomValuesToPx[Math.trunc(item.zoom) * 5].pixels / 10)
          }
        })
        const map = document.getElementById('ymap')
        if (map) {
          const mapSectionWidth = map.getBoundingClientRect().width
          const mapSectionHeight = map.getBoundingClientRect().height
          const geoZoneRadiusInPixels = mapSectionWidth < mapSectionHeight ? ((mapSectionWidth / 2) / data.radius).toFixed(3) : ((mapSectionHeight / 2) / data.radius).toFixed(3)
          newZoom = mapZoomValuesToPx.find(item => geoZoneRadiusInPixels < item.pixels)?.zoom || 3
        }
      }
      if (type === INTEGRATION || type === PROJECT) {
        newZoom = 12
      }
      if (latitude && longitude && selectedElementParams.id !== parentGeoZoneId) {
        yield put(setSelectedElementParams({
          id: type === GEOZONE || type === ROOT ? id : parentGeoZoneId,
        }))
      }
    }
    const formattedData = {
      ...data,
      ...parentElementName,
      name,
      id: data.treeElementId || data.id,
      identifier: data.objectIdOuter,
      point: {
        latitude,
        longitude
      },
      ...elementsInfo,
      type,
    }
    let objectTypes = []
    if (type === OBJECT_ELEMENT || type === INTEGRATION) {
      if (latitude && longitude && selectedElementParams.id !== id) {
        yield put(setSelectedElementParams({ id, parentGeoZoneId }))
      }

      if (createMode) {
        const { data: availableObjectTypes } = yield request({
          url: generateGetTypes(data.integrationType, data.installationType),
          method: 'get',
        })
        objectTypes = availableObjectTypes.map((name) => ({
          value: name,
          title: TYPE_TO_NAME[name],
        }))
        formattedData.isCreateBlock = availableObjectTypes.length === 0
        formattedData.availableObjectTypes = availableObjectTypes.length === 0
      }

      const integrationType = get(data, 'integrationType', null)
      const objectType = get(data, 'installationType', null)
      const cardAndPassportConfig = get(installationPassportConfig, integrationType, [])
      const cardConfig = get(cardAndPassportConfig, `card.${objectType}`, [])
      const passportConfig = get(cardAndPassportConfig, `passport.${objectType}`, [])
      const config = [...cardConfig, ...passportConfig]

      const formattedObjectFields = (data.objectFields || []).map((element) => {
        const localName = `${element.passportBlock}.${element.fieldName}`
        if (isInConfig(localName, config) || isAdditionalFields(element.fieldName)) {
          return {
            ...element,
            fieldName: localName,
          }
        }
        return element
      })
      const filterElementFields = formattedObjectFields.filter((element) => element.fieldName.split('.')[0] !== 'ATTACHMENT')
      const attachment = formattedObjectFields.filter((element) => element.fieldName.split('.')[0] === 'ATTACHMENT')
      const photoFileId = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.PHOTO_ID')[0] || {}).value || null
      const PDFFileId = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.DOCUMENT_ID')[0] || {}).value || null
      const photoFileName = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.PHOTO_TITLE')[0] || {}).value || null
      const PDFFileName = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.DOCUMENT_TITLE')[0] || {}).value || null
      try {
        if (photoFileId) {
          const { data: photoFile } = yield request({
            url: `${GET_PASSPORT_FILES_URL}?id=${photoFileId}`,
            method: 'get',
            options: {
              responseType: 'blob',
            },
          })
          const photoFileFromBlob = new window.File([photoFile], photoFileName, { type: 'image/png' })
          filterElementFields.push({
            passportBlock: 'ATTACHMENT',
            fieldName: 'ATTACHMENT.PHOTO_TITLE',
            value: photoFileFromBlob,
          })
        }
      } catch (photoError) {
        console.log('photoError', photoError)
      }
      try {
        if (PDFFileId) {
          const { data: PDFFile } = yield request({
            url: `${GET_PASSPORT_FILES_URL}?id=${PDFFileId}`,
            method: 'get',
            options: {
              responseType: 'blob',
            },
          })
          const fileFileFromBlob = new window.File([PDFFile], PDFFileName, { type: 'application/pdf' })
          filterElementFields.push({
            passportBlock: 'ATTACHMENT',
            fieldName: 'ATTACHMENT.DOCUMENT_TITLE',
            value: fileFileFromBlob,
          })
        }
      } catch (fileError) {
        console.log('fileError', fileError)
      }
      formattedData.objectElementFieldsDto = filterElementFields
      formattedData.photoId = photoFileId
      formattedData.fileId = PDFFileId
    }
    const sortedObjectTypes = sortOptionsByTitle(objectTypes)
    formattedData.collumnId = collumnId
    yield put(successGetCard(formattedData))
    yield put(successGetObjectTypes(sortedObjectTypes))
    if (isLiveTime) {
      yield put(setSelectedNode(formattedData))
    }
  } catch (error) {
    yield put(errorGetCard())
    console.log('function*getCardSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные карточки. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_CARD.REQUEST, getCardSaga)
}

import {
  put, takeLatest, select
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { getObjectStates } from '@/helpers/getObjectStates'
import { successGetRootElements, successGetTreeElements } from '@/store/actions/installation/getRootElemenet'
import { SET_SEARCH_TREE } from '@/store/actions/appSettings'
import createNotifications from '@/helpers/notification'
import { getGlobalFilters, getSearchTree } from '@/store/selectors/appSettings'
import { buildTree, buildTreeTelemetry } from '@/constants/apiRoutes'


function* getTree({payload}) {
  yield put(successGetRootElements(null))
  const {href} = window.location
  const objectStates = getObjectStates(href)
  const searchTree = yield select(getSearchTree)
  try {
    if (searchTree !== ''){
      const globalFilters = yield select(getGlobalFilters)
      const { asu , objectsTypes, integrations, processes, owners, exploitationOrganizations, ...statuses} = globalFilters
      const statusesTrue = Object.keys(statuses).filter((status) => statuses[status])
      const telemetryStatuses = statusesTrue.length === Object.keys(statuses).length ? [] : statusesTrue
      const url = href.includes('maps') ? buildTreeTelemetry : buildTree
      const newData = yield request({
        url: url({
          params: {
            objectStates,
            pageSize: 99999,
            searchText: searchTree,
            integrationTypes: asu,
            installationTypes: objectsTypes,
            integrationIds: integrations,
            cityProcessTypes: processes,
            telemetryStatuses,
            owners,
            exploitationOrganizations,
          }
        }),
        method: 'get',
      })
      
      yield put(successGetTreeElements(newData.data.content))
    } 
  } catch (error) {
    console.log('function*getRootElementSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось осуществить поиск.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(SET_SEARCH_TREE, getTree)

}

import React, {
  useCallback, useEffect, useState, useRef,
} from 'react'
import pt from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import get from 'lodash/get'
import Loader from '@/components/blocks/Loader'
import {
  OBJECT_ELEMENT,
  GROUP,
} from '@/constants/objectTypes'
import {
  PASSPORT,
} from '@/constants/passportization'
import PassportAndCard from './components/PassportAndCard'
import PassportHeader from './components/PassportHeader'
import FieldsSettings from './components/FieldsSettings'

import {
  Container,
  LoaderContainer,
  ScrollableWrapper,
} from './styles'
import cardAndPassportByType from '@/constants/instalationPassport'
import { formatDataForObject, handlePhotoConvertToBase64 } from '@/containers/pages/Installation/utils'
import {
  CONNECTED_LINES_OBJECT_TYPES,
  LINE,
  UTILITY_POLE
} from '@/constants/instalationPassport/types'
import GlobalPopupMobile from '@/components/blocks/GlobalPopupMobile/component'

const PassportSidebar = ({
  selectedNode,
  intl,
  passportIsOpen,
  operatedElement,
  setOperatedElement,
  selectedElementEquipment,
  data,
  userData,
  requestStatus,
  pinnedNode,

  requestAddPassportFile,
  requestAddPassportPhoto,
  requestDeletePassportFile,
  requestDeletePassportPhoto,
  requestExportPassportFile,
  requestGetPassportFile,
  requestUpdatePassport,
  requestGetObjectConnectedIdentifiers,
  requestGetConnectedLinesCoordinates,
  objectConnectedIdentifiers,
  mapLayer,
  isMobile,
  onClosePassport,
  onSetSelectedNode,
  onSetUnpinNode,
  activeFieldsPassport,
  submitFieldsPassport,
}) => {
  const passportFormRef = useRef(null)
  const [selectedFields, setSelectedFields] = useState([])
  const [isParentPassportOpen, setIsParentPassportOpen] = useState(passportIsOpen)
  const [uploadedImage, setUploadedImage] = useState(null)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [editPassport, setEditPassport] = useState(null)
  const [editForm, setEditForm] = useState(false)
  const [tabType, setTabType] = useState(PASSPORT)
  const [isPopupOpen, setIsPopupOpen] = useState({})
  const passportByType = get(cardAndPassportByType, `${data.integrationType}.passport.${data.installationType}`, [])
  const cardByType = get(cardAndPassportByType, `${data.integrationType}.card.${data.installationType}`, [])
  const isExportButtonVisible = data.installationType && data.installationType !== GROUP
  useEffect (() => {
    if (selectedNode && operatedElement && selectedNode.id !== operatedElement.id) {
      setOperatedElement(selectedNode)
      if (CONNECTED_LINES_OBJECT_TYPES.some(type => type === selectedNode.type)) {
        requestGetObjectConnectedIdentifiers({
          parentId: selectedNode.type === LINE || selectedNode.type === UTILITY_POLE ? selectedNode.parentId : selectedNode.id,
        })
        // if (mapLayer === CONNECTED_LINES) {
        //   requestGetConnectedLinesCoordinates({
        //     id: selectedNode.id,
        //     parentId: selectedNode.parentId
        //   })
        // }
      }
    }
  }, [selectedNode, setOperatedElement, operatedElement, requestGetObjectConnectedIdentifiers, requestGetConnectedLinesCoordinates, mapLayer])

  useEffect(() => {
    const objectData = selectedNode.treeNodeType !== OBJECT_ELEMENT ? {} : formatDataForObject(data, intl)
    const photo = get(objectData, 'ATTACHMENT.PHOTO_TITLE', null)
    if (photo) {
      handlePhotoConvertToBase64(photo, setUploadedImage)
    } else {
      setUploadedImage(null)
    }

    setEditPassport(userData.permissions.applicationModules
        .filter((applicationModule) => applicationModule.applicationId === 3)
        .some((applicationModule) => applicationModule.code === "PASSPORTIZATION_EDIT"))
    if (Object.keys(activeFieldsPassport['privatSettings'] || {}).includes(`${data.id}`)) {
      setSelectedFields(activeFieldsPassport['privatSettings'][data.id])
      return
    }
    if (!activeFieldsPassport[data.installationType] && !isEmpty(data)) {
      const selectedFieldsInitialValues = get(cardAndPassportByType, `${data.integrationType}.passport.${data.installationType}`, [])
      .concat(get(cardAndPassportByType, `${data.integrationType}.card.${data.installationType}`, [])).map(item => item.selector)
      setSelectedFields(selectedFieldsInitialValues)
    } else {
      setSelectedFields(activeFieldsPassport[data.installationType])
    }
   
  }, [data, intl, selectedNode, userData, activeFieldsPassport])

  const setActiveFieldsAll = (fields) => {
    delete activeFieldsPassport['privatSettings']
    submitFieldsPassport({[data.path[2]]: {
      ...activeFieldsPassport,
      [data.installationType]: fields
    }})
    setIsSettingsOpen(false)
  }

  const setAciveFieldsObject = (fields) => {
    submitFieldsPassport({[data.path[2]]: {
      ...activeFieldsPassport,
      'privatSettings' : {
        ...activeFieldsPassport['privatSettings'],
        [data.id]: fields
      }
    }})
    setIsSettingsOpen(false)
  }

  const handleResetForm = useCallback(() => {
    setUploadedImage(null)
    passportFormRef.current.resetForm()
  }, [])

  const handleClickButton = () => {
    setIsPopupOpen({value: false})
  }

  const handlePinIconClick = () => {
    setIsPopupOpen({value: true})
  }

  const handleUnpinNode = () => {
    onSetUnpinNode({})
    setIsPopupOpen({value: false})
  }

  const handleSetSelectedNode = () => {
    onSetSelectedNode(selectedNode)
    setIsPopupOpen({value: false})
  }

  const handleSubmitForm = useCallback(() => {
    if (passportFormRef && passportFormRef.current && dirty) {
      setEditForm(false)
      setDirty(false)
      get(passportFormRef, 'current.setSubmitting', noop)(true)
      get(passportFormRef, 'current.handleResetForm', noop)()
      requestUpdatePassport({
        type: operatedElement.installationType,
        values: passportFormRef.current.state.values,
        form: passportFormRef.current,
        name: passportFormRef.current.state.values.GENERAL_INFORMATION.NAME,
        id: selectedNode.id,
        asuId: selectedNode.asuId,
        parentName: selectedNode.parentName,
        parentId: selectedNode.parentId,
        photoId: data.photoId,
        fileId: data.fileId
      })
    }
  }, [
    passportFormRef,
    requestUpdatePassport,
    operatedElement,
    selectedNode,
    dirty,
    data.photoId,
    data.fileId,
  ])

  // const onSelectedEquipmentChangeHandler = (value) => {
  //   const selectedElement = selectedElementEquipment
  //     .filter((element) => element.value === value)[0] || {}
  //   setOperatedElement({
  //     ...selectedElement,
  //     isParent: selectedElement.value === operatedElement.type,
  //   })
  //   setUploadedImage(null)
  // }

  const handleDownloadFile = useCallback(() => {
    requestGetPassportFile({
      getAndDownload: true,
      id: get(data, 'fileId', null),
      name: get(passportFormRef, 'current.state.values.ATTACHMENT.DOCUMENT_TITLE.name', null)
    })
  }, [data, requestGetPassportFile, passportFormRef])

  const handleExportFile = useCallback(() => {
    requestExportPassportFile({
      id: selectedNode.id,
      operatedElement,
      selectedFields,
    })
  }, [operatedElement, selectedNode, requestExportPassportFile, selectedFields])

  const handleRequestAddPassportFile = useCallback(() => {
    const { id } = selectedNode
    const fileName = get(data, 'file.title', null)
    const photoName = get(data, 'photo.title', null)
    const passportId = get(data, 'generalInfo.passportId', null)
    const fileValue = get(passportFormRef, 'current.state.values.inner.file', null)
    const photoValue = get(passportFormRef, 'current.state.values.inner.photo', null)
    if (fileValue && fileValue.name) {
      requestAddPassportFile({
        form: passportFormRef.current,
        id,
        type: operatedElement.installationType,
        passportId,
      })
    }
    if (photoValue && photoValue.name) {
      requestAddPassportPhoto({
        form: passportFormRef.current,
        id,
        type: operatedElement.installationType,
        passportId,
      })
    }
    if (!fileValue && fileName) {
      requestDeletePassportFile({
        id: get(data, 'file.uid', null),
        type: operatedElement.installationType,
        passportId,
      })
    }
    if (!photoValue && photoName) {
      requestDeletePassportPhoto({
        id: get(data, 'photo.uid', null),
        type: operatedElement.installationType,
        passportId,
      })
    }
  },
  [
    data,
    requestAddPassportFile,
    requestAddPassportPhoto,
    requestDeletePassportFile,
    requestDeletePassportPhoto,
    selectedNode,
    operatedElement.installationType
  ])
  
  return (
    passportIsOpen &&
    <Container>
       {isPopupOpen.value && 
      <GlobalPopupMobile 
        onClose={handleClickButton} 
        handleSetSelectedNode={handleSetSelectedNode} 
        handleUnpinNode={handleUnpinNode} 
        pinnedNode={pinnedNode}/>
    }
      <PassportHeader
        selectedNode={selectedNode}
        name={data.name}
        toggleSettings={setIsSettingsOpen}
        isParentPassportOpen={isParentPassportOpen}
        setIsParentPassportOpen={setIsParentPassportOpen}
        isSettingsOpen={isSettingsOpen}
        setOperatedElement={setOperatedElement}
        dirty={dirty}
        handleResetForm={handleResetForm}
        requestAddPassportFile={handleRequestAddPassportFile}
        submitForm={handleSubmitForm}
        noOptions={selectedElementEquipment.length === 0}
        editPassport={editPassport}
        setEditForm={setEditForm}
        passportFormRef={passportFormRef}
        requestStatus={requestStatus}
        isMobile={isMobile}
        onClosePassport={onClosePassport}
        handlePinIconClick={handlePinIconClick}
        pinnedNode={pinnedNode}
      />
      <ScrollableWrapper>
        {/*{!(isSettingsOpen || isParentPassportOpen)*/}
        {/*&& selectedElementEquipment.length !== 0 && (*/}
        {/*<SelectControlContainer>*/}
        {/*  <Title>*/}
        {/*    <Lang id="passportization.passportSidebar.equipment" />*/}
        {/*  </Title>*/}
        {/*  <SelectControl*/}
        {/*    withSearch*/}
        {/*    placeholder="Выбрать оборудование"*/}
        {/*    value={selectedElementEquipment.find(*/}
        {/*      (element) => element.type === operatedElement.type,*/}
        {/*    )*/}
        {/*      && operatedElement.type}*/}
        {/*    options={selectedElementEquipment}*/}
        {/*    onChange={onSelectedEquipmentChangeHandler}*/}
        {/*  />*/}
        {/*</SelectControlContainer>*/}
        {/*)}*/}
        {!isSettingsOpen && (!isEmpty(operatedElement) || isParentPassportOpen) && (
          <>
            {Object.keys(data).length
              ? (
                <PassportAndCard
                  cardData={data}
                  cardScheme={cardByType}
                  passportScheme={passportByType}
                  passportData={data}
                  exportPassport={noop}
                  exportCart={noop}
                  selectedFields={selectedFields}
                  setDirty={setDirty}
                  passportRef={passportFormRef}
                  uploadedImage={uploadedImage}
                  setUploadedImage={setUploadedImage}
                  handleExportFile={handleExportFile}
                  handleDownloadFile={handleDownloadFile}
                  block={!editForm}
                  tabType={tabType}
                  setTabType={setTabType}
                  intl={intl}
                  isExportButtonVisible={isExportButtonVisible}
                  objectConnectedIdentifiers={objectConnectedIdentifiers}
                  isMobile={isMobile}
                  collumnId={selectedNode.collumnId}
                />
              )
              : (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>

              )}
          </>
        )}
        {isSettingsOpen && (
          <FieldsSettings
            passportConfig={passportByType}
            cardConfig={cardByType}
            selectedFields={selectedFields}
            setActiveFieldsAll={setActiveFieldsAll}
            setAciveFieldsObject={setAciveFieldsObject}
          />
        )}
      </ScrollableWrapper>
    </Container>
  )
}


PassportSidebar.propTypes = {
  type: pt.string,
  selectedNode: pt.objectOf(pt.object),
  data: pt.objectOf(pt.object),
  requestStatus: pt.string,
  operatedElement: pt.objectOf(pt.object),
  selectedElementEquipment: pt.objectOf(pt.object),
  setOperatedElement: pt.func,
  requestObjectEquipment: pt.func,
  requestUpdatePassport: pt.func,
}


PassportSidebar.defaultProps = {
  type: '',
  selectedNode: {},
  requestStatus: '',
  operatedElement: {},
  selectedElementEquipment: [],
  setOperatedElement: noop,
  requestObjectEquipment: noop,
  requestUpdatePassport: noop,
  data: {},
}

export default PassportSidebar

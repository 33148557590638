import React, { useEffect, useState, useCallback } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
// import { treeHandlers } from 'react-hyper-tree'
import { tree as treeMash } from '@/data/mapData'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import UsersIcons from '@/components/icons/users'
import TextControl from '@/components/controls/TextControl'
import { NavContainer } from '../../styles'
import debounce from 'lodash/debounce'
import {
  DEBOUNCE_DELAY_LARGE,
} from '@/constants/time'

const TREE_ID = 'installations-tree'

const TreeSidebar = ({
      roots,
      rootStatus,
      onSelect,
      title,
      hidden,
      selectedNode,
      setSelectedNode,
      treeData,
      treeId,
      isMonitoring,
      isChildren,
      isMashMode,
      searchTree,
      setSearchTree,
      onCloseTree,
      isMobile,
      user,
      globalFilters,
      treeDataStatick,
      resetSearchTree,
      setTrueStatusGlobalFilter,
      ...props
    }) => {
    const [search, setSearch] = useState('')
    const [searchLoading, setSearchLoading] = useState(false)

    useEffect(() => {
      setSearch('')
    }, [isMashMode])

    useEffect(() => {
      if (!searchTree) {
        setSearch('')
        setSearchQuery('')
      }
    }, [searchTree])

    const [searchQuery, setSearchQuery] = useState('')
    // const handleSearchChange = (value) => {
      // if (!value && selectedNode.id) {
      //   const tree = treeHandlers.trees[TREE_ID]
      //   setTimeout(() => {
      //     const selectedNodePath = `${selectedNode.path.join('/')}/${selectedNode.id}`
      //     tree.handlers.setSelectedByPath(selectedNodePath)
      //   })
      // }
      // setSearch(value)
    // }

    const onChangeSearch = useCallback(debounce((value) => {
        setSearchQuery(value)
        setSearchTree(value)
        setSearchLoading(false)
    }, DEBOUNCE_DELAY_LARGE), [])
  

    const resetSearch = () => {
      setTrueStatusGlobalFilter()
      onChangeInput('')
      setSearchTree('')
      setSearchLoading(false)
      resetSearchTree()
    }

    const onChangeInput = (value) => {
      setSearch(value)
      if (String(value).length >= 3 || value === '') {
        setSearchLoading(true)
        onChangeSearch(value)
      }
      if(value === '') {
        setTrueStatusGlobalFilter()
        resetSearchTree()
      }
    }

    const getTreeData = () => {
        if (isMashMode) {
          return [treeMash]
        }

        if (searchQuery) {
          return treeDataStatick
        }

        return treeData ? treeData : roots
    }

    return (
        <NavContainer hidden={hidden}>
            <PageSidebar
                {...props}
                title={title}
                treeId={treeId ? treeId : TREE_ID}
                treeData={getTreeData()}
                rootStatus={rootStatus}
                onSelect={onSelect}
                searchQuery={searchQuery}
                selectedNode={selectedNode}
                isMonitoring={isMonitoring}
                isChildren={isChildren}
                searchTree={searchTree}
                setSearchTree={setSearchTree}
                isMashMode={isMashMode}
                onCloseTree={onCloseTree}
                isMobile={isMobile}
                user={user}
                searchLoading={searchLoading}
                globalFilters={globalFilters}
                headerContent={(
                  <Lang id={'Поиск по идентификатору'}>
                      {(placeholder) => (
                          <TextControl
                              dark
                              placeholder={placeholder}
                              name="search"
                              icon={UsersIcons.MagnifierIcon}
                              onChange={onChangeInput}
                              value={search}
                              resetButton
                              resetField={resetSearch}
                          />
                      )}
                  </Lang>
                )}
            />
        </NavContainer>
    )

}

export default React.memo(TreeSidebar)
